export const TELTONIKA = [
    { label: 'Reconnect', command: 'track', icon: 'Track', isPopUp: false, types: [1] },
    { label: 'Get Location', command: 'track', icon: 'Locations', isPopUp: false, types: [1, 2] }
];

export const TELTONIKA_TST100 = [
    { label: 'Light On', command: 'lightOn', icon: 'LightOn', isPopUp: false, types: [1] },
    { label: 'Light Off', command: 'lightOff', icon: 'LightOff', isPopUp: false, types: [1] },
    { label: 'Alarm On', command: 'alarmOn', icon: 'AlarmOn', isPopUp: false, types: [1, 2] },
    { label: 'Alarm Off', command: 'alarmOff', icon: 'AlarmOff', isPopUp: false, types: [1, 2] },
    { label: 'Max Speed', command: 'setMaxSpeed', icon: 'SetMaxSpeed', isPopUp: true, types: [1], key: 'maxSpeedLimit' },
    { label: 'Turn Off the engine', command: 'turnOffTheEngine', icon: 'LightOff', isPopUp: false, types: [1]},
    { label: 'Turn ON the engine', command: 'turnOnTheEngine', icon: 'LightOn', isPopUp: false, types: [1]},
    { label: 'Turn Off Look for the Scooter', command: 'turnOffLookScooter', icon: 'LightOff', isPopUp: false, types: [1]},
    { label: 'Turn ON Look for the Scooter', command: 'turnOnLookScooter', icon: 'LightOn', isPopUp: false, types: [1]},
    { label: 'BatteryLock Unlock', command: 'batteryLockUnlock', icon: 'Batunlock', isPopUp: false, types: [1] },
    { label: 'CableLock Unlock', command: 'cableLockUnlock', icon: 'CableLockUnlock', isPopUp: false, types: [1] },
    { label: 'Reconnect', command: 'track', icon: 'Track', isPopUp: false, types: [1] },
    { label: 'Get Location', command: 'track', icon: 'Locations', isPopUp: false, types: [1, 2] },
    { label: 'Get Normal Speed Limit', command: 'getNormalMaxSpeed', icon: 'SetMaxSpeed', isPopUp: false, types: [1, 2] },
    { label: 'Get Eco Speed Limit', command: 'getEcoMaxSpeed', icon: 'SetMaxSpeed', isPopUp: false, types: [1, 2] },
    { label: 'Get Sport Speed Limit', command: 'getSportMaxSpeed', icon: 'SetMaxSpeed', isPopUp: false, types: [1, 2] }
];

export const TELTONIKA_TST100_FIT_RIDER = [
    { label: 'Light On', command: 'lightOn', icon: 'LightOn', isPopUp: false, types: [1] },
    { label: 'Light Off', command: 'lightOff', icon: 'LightOff', isPopUp: false, types: [1] },
    { label: 'Alarm On', command: 'alarmOn', icon: 'AlarmOn', isPopUp: false, types: [1, 2] },
    { label: 'Alarm Off', command: 'alarmOff', icon: 'AlarmOff', isPopUp: false, types: [1, 2] },
    { label: 'Max Speed', command: 'setMaxSpeed', icon: 'SetMaxSpeed', isPopUp: true, types: [1], key: 'maxSpeedLimit' },
    { label: 'BT Lock Unlock', command: 'btLockUnlock', icon: 'CableLockUnlock', isPopUp: false, types: [1] }
];

export const TELTONIKA_TFT100 = [
    { label: 'Boot Open', command: 'bootOpen', icon: 'BootOpen', isPopUp: false, types: [1] },
    { label: 'Boot Close', command: 'bootClose', icon: 'BootClose', isPopUp: false, types: [1] }
];

export const TELTONIKA_FMB920 = [
    { label: 'Boot Open', command: 'bootOpen', icon: 'BootOpen', isPopUp: false, types: [1] },
    { label: 'Boot Close', command: 'bootClose', icon: 'BootClose', isPopUp: false, types: [1] }
];

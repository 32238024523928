import { SWITCH_LANGUAGE, CHANGE_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH, SET_CURRENCY_DATA, SET_PREFERRED_CURRENCY, SET_DEFAULT_CURRENCY_OBJ } from "constants/ActionTypes";
import { LAYOUT_TYPE, NAV_STYLE, THEME_COLOR_SELECTION, THEME_TYPE } from "../../constants/ThemeSetting";

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeType(themeType) {
  return { type: THEME_TYPE, themeType };
}

export function setThemeColorSelection(colorSelection) {
  return { type: THEME_COLOR_SELECTION, colorSelection };
}

export function onNavStyleChange(navStyle) {
  return { type: NAV_STYLE, navStyle };
}

export function onLayoutTypeChange(layoutType) {
  return { type: LAYOUT_TYPE, layoutType };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

export function changeLanguage(language) {
  return {
    type: CHANGE_LANGUAGE,
    payload: language
  };
}

export function setCurrencyData(currencyData) {
  return {
    type: SET_CURRENCY_DATA,
    payload: currencyData
  };
}

export function setPreferredCurrency(preferredCurrency) {
  return {
    type: SET_PREFERRED_CURRENCY,
    payload: preferredCurrency
  };
}

export const ELEMENT = [
    { label: 'Light On', command: 'lightOn', icon: 'LightOn', isPopUp: false, types: [2] },
    { label: 'Light Off', command: 'lightOff', icon: 'LightOff', isPopUp: false, types: [2] },

    { label: 'Alarm On', command: 'alarmOn', icon: 'AlarmOn', isPopUp: false, types: [2] },
    { label: 'Alarm Off', command: 'alarmOff', icon: 'AlarmOff', isPopUp: false, types: [2] },
    { label: 'Beep On', command: 'vehicleSearch', commandValue: 1, icon: 'Buzon', isPopUp: false, types: [2], isNewMethod: true },
    { label: 'Siren On', command: 'vehicleSearch', commandValue: 2, icon: 'AutoPlayEnable', isPopUp: false, types: [2], isNewMethod: true },

    { label: 'Reconnect', command: 'track', icon: 'Track', isPopUp: false, types: [2] },
    { label: 'Get Location', command: 'location', icon: 'Locations', isPopUp: false, types: [2] },

    //add commandValue high, normal, low
    { label: 'Max Speed', command: 'setMaxSpeed', commandValue: 35, icon: 'SetMaxSpeed', isPopUp: true, types: [2], key: 'maxSpeedLimit' },

    { label: 'Ping Interval', command: 'setPingInterval', icon: 'SetPingInterval', isPopUp: true, types: [2], key: 'pingInterval' },
    { label: 'Ride Ping Interval', command: 'setRidePingInterval', icon: 'SetRidePingInterval', isPopUp: true, types: [2], key: 'ridePingInterval' },
    { label: 'Position Ping Interval', command: 'setPositionPingInterval', icon: 'configuration', isPopUp: true, types: [2], key: 'positionPingInterval' },

    { label: 'Allow To Lock', command: 'allowOrForbidToLock', commandValue: 0, icon: 'Lock', isPopUp: false, types: [2], isNewMethod: true },
    { label: 'Forbid To Lock', command: 'allowOrForbidToLock', commandValue: 1, icon: 'Unlock', isPopUp: false, types: [2], isNewMethod: true },

    { label: 'Outside Zone On', command: 'throttleOn', icon: 'Idleledon', isPopUp: false, types: [2] },
    { label: 'Outside Zone Off', command: 'throttleOff', icon: 'Idleledoff', isPopUp: false, types: [2] },

    { label: 'Check Battery Information', command: 'batteryInfo', icon: 'BatteryStatus', isPopUp: false, types: [2], isNewMethod: true },

    { label: 'Version Information', command: 'versionInfo', icon: 'Getconfig', isPopUp: false, types: [2], isNewMethod: true },

    { label: 'Add To Maintenance', command: 'inMaintenance', icon: 'CableLock', isPopUp: false, types: [2], isNewMethod: true },
    { label: 'Remove From Maintenance', command: 'outOfMaintenance', icon: 'CableLockUnlock', isPopUp: false, types: [2], isNewMethod: true },

    { label: 'Reserve Vehicle', command: 'reservation', icon: 'Listplay', isPopUp: false, types: [2], isNewMethod: true },
    { label: 'Cancel Reservation', command: 'cancelReservation', icon: 'AutoPlayDisable', isPopUp: false, types: [2], isNewMethod: true },
];